define("ev/templates/settings/calendar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "QCZBeaJh",
    "block": "{\"symbols\":[],\"statements\":[[10,\"p\"],[12],[1,[30,[36,0],[\"settings.calendar.description\"],null]],[13],[2,\"\\n\\n\"],[6,[37,2],[[35,1,[\"user\",\"calendar_hash\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"\\t\"],[10,\"p\"],[12],[2,\"\\n\\t\\t\"],[10,\"a\"],[15,6,[31,[\"webcal://api.erasmusvolley.nl/calendar/\",[34,1,[\"user\",\"calendar_hash\"]]]]],[14,0,\"btn btn-outline-secondary btn-outline btn-sm\"],[12],[8,\"fa-icon\",[],[[\"@icon\",\"@prefix\"],[\"apple\",\"fab\"]],null],[2,\" Apple Calendar (Mac, iPhone)\"],[13],[2,\"\\n\\t\\t\"],[10,\"a\"],[15,6,[31,[\"https://www.google.com/calendar/render?cid=http://api.erasmusvolley.nl/calendar/\",[34,1,[\"user\",\"calendar_hash\"]]]]],[14,\"rel\",\"noreferrer\"],[14,\"target\",\"_blank\"],[14,0,\"btn btn-outline-primary btn-outline btn-sm\"],[12],[8,\"fa-icon\",[],[[\"@icon\",\"@prefix\"],[\"google\",\"fab\"]],null],[2,\" Google Calendar (Gmail, Android)\"],[13],[2,\"\\n\\t\"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"\\t\"],[10,\"p\"],[14,0,\"alert alert-success\"],[12],[8,\"fa-icon\",[],[[\"@icon\"],[\"hourglass-half\"]],null],[2,\"  \"],[1,[30,[36,0],[\"settings.calendar.not_available\"],null]],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[10,\"div\"],[14,0,\"small\"],[12],[1,[30,[36,0],[\"settings.calendar.disclaimer\"],null]],[2,\" Roy de Vos Burchart.\"],[13]],\"hasEval\":false,\"upvars\":[\"t\",\"currentUser\",\"if\"]}",
    "meta": {
      "moduleName": "ev/templates/settings/calendar.hbs"
    }
  });

  _exports.default = _default;
});
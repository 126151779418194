define("ev/components/announcement", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <li class="media">
      <div class="media-body">
          <h4>{{#if @icon}}<FaIcon @icon={{@icon}} class="text-muted" /> {{/if}}{{ @announcement.title }}</h4>
          <p class="summary">{{ @announcement.summary }}</p>
          {{#if @announcement.action_url}}
              <p class="action-row">
                  <a href="{{ @announcement.action_url }}" target="_blank" rel="noreferrer">{{@ announcement.action_text }}</a>
              </p>
          {{/if}}
      </div>
  </li>
  */
  {
    "id": "iciKsxLk",
    "block": "{\"symbols\":[\"@announcement\",\"@icon\"],\"statements\":[[10,\"li\"],[14,0,\"media\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"media-body\"],[12],[2,\"\\n        \"],[10,\"h4\"],[12],[6,[37,0],[[32,2]],null,[[\"default\"],[{\"statements\":[[8,\"fa-icon\",[[24,0,\"text-muted\"]],[[\"@icon\"],[[32,2]]],null],[2,\" \"]],\"parameters\":[]}]]],[1,[32,1,[\"title\"]]],[13],[2,\"\\n        \"],[10,\"p\"],[14,0,\"summary\"],[12],[1,[32,1,[\"summary\"]]],[13],[2,\"\\n\"],[6,[37,0],[[32,1,[\"action_url\"]]],null,[[\"default\"],[{\"statements\":[[2,\"            \"],[10,\"p\"],[14,0,\"action-row\"],[12],[2,\"\\n                \"],[10,\"a\"],[15,6,[31,[[32,1,[\"action_url\"]]]]],[14,\"target\",\"_blank\"],[14,\"rel\",\"noreferrer\"],[12],[1,[32,1,[\"action_text\"]]],[13],[2,\"\\n            \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"    \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"if\"]}",
    "meta": {
      "moduleName": "ev/components/announcement.hbs"
    }
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});
define("ev/templates/landing/register/left", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "jDyLOe0n",
    "block": "{\"symbols\":[],\"statements\":[[1,[30,[36,1],[[30,[36,0],[\"auth.signup.title\"],null]],null]],[2,\"\\n\\n\"],[11,\"form\"],[24,0,\"active\"],[4,[38,2],[\"submit\",[32,0,[\"register\"]]],null],[12],[2,\"\\n\"],[6,[37,3],[[32,0,[\"alertMessage\"]]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[10,\"div\"],[15,0,[31,[\"alert alert-\",[32,0,[\"alertType\"]],\" mt-3\"]]],[12],[2,\"\\n            \"],[8,\"fa-icon\",[],[[\"@icon\"],[\"exclamation-triangle\"]],null],[2,\" \"],[1,[30,[36,0],[[32,0,[\"alertMessage\"]]],null]],[2,\"\\n        \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"    \"],[10,\"div\"],[14,0,\"form-group\"],[12],[2,\"\\n        \"],[8,\"input\",[[24,0,\"form-control\"],[16,\"placeholder\",[30,[36,0],[\"auth.enter_email\"],null]]],[[\"@type\",\"@value\"],[\"email\",[32,0,[\"email\"]]]],null],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"button\"],[14,0,\"btn login-button\"],[15,\"disabled\",[32,0,[\"loading\"]]],[14,4,\"submit\"],[12],[2,\"\\n        \"],[1,[30,[36,0],[[30,[36,3],[[32,0,[\"loading\"]],\"auth.pleasewait\",\"auth.signup.submit\"],null]],null]],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"mt-3 mb-0 pt-3 text-center\"],[14,5,\"border-top: 1px solid white;\"],[12],[2,\"\\n        \"],[8,\"link-to\",[[24,0,\"text-white\"]],[[\"@route\"],[\"landing.signin\"]],[[\"default\"],[{\"statements\":[[1,[30,[36,0],[\"auth.alreadyregistered\"],null]]],\"parameters\":[]}]]],[2,\"\\n    \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"t\",\"page-title\",\"on\",\"if\"]}",
    "meta": {
      "moduleName": "ev/templates/landing/register/left.hbs"
    }
  });

  _exports.default = _default;
});
define("ev/overrides/linkview", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.LinkComponent.reopen({
    intl: Ember.inject.service(),
    tooltip: false,
    attributeBindings: ["data-placement", "data-animation"],
    "data-placement": null,
    init: function init() {
      var self = this;
      this.intl; // bind attributes beginning with 'data-'

      Object.keys(this).forEach(function (key) {
        if (key.substr(0, 5) === "data-" && self.get("attributeBindings").indexOf(key) === -1) {
          self.get("attributeBindings").pushObject(key);
        }
      });

      this._super();
    },
    _titleChange: Ember.observer("title", function () {
      if (this.tooltip === true) {
        this.$().attr("data-original-title", this.title);
      }
    }),
    _tooltipChange: Ember.observer("tooltip", function () {
      if (this.tooltip === false) {
        this.$().tooltip("dispose");
      } else {
        this.$().tooltip({
          trigger: "hover",
          container: "body",
          animation: false
        });
      }
    }),
    didInsertElement: function didInsertElement() {
      this._super();

      if (this.tooltip === true) {
        this.$().tooltip({
          trigger: "hover",
          container: "body",
          animation: false
        });
      }

      if (this.$().hasClass("waves-effect")) {
        Waves.attach(this.$());
      }

      if (Object.keys(this).indexOf("tab") > -1) {
        this.$().tab();
      }
    },
    click: function click() {
      this._super();

      if (this.tooltip === true) {
        this.$().tooltip("hide");
      }
    }
  });

  _exports.default = _default;
});
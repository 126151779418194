define("ev/templates/head", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "K1q4gEve",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,0],[[32,0,[\"model\",\"notifications\",\"unread\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[10,\"title\"],[12],[2,\"(\"],[1,[32,0,[\"model\",\"notifications\",\"unread\"]]],[2,\") \"],[1,[32,0,[\"model\",\"safeTitle\"]]],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[10,\"title\"],[12],[1,[32,0,[\"model\",\"safeTitle\"]]],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"if\"]}",
    "meta": {
      "moduleName": "ev/templates/head.hbs"
    }
  });

  _exports.default = _default;
});
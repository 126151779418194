define("ev/components/team/index-card", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <LinkTo @route="team.show" @model={{@teamseason.team.code}} @query={{hash season=null}} class="team-card">
      <div class="card">
          <div class="mdl-card__title team-photo-block">
              <div class="team-photo-block-text">{{ @teamseason.team.code }}</div>
              {{#if @teamseason.photo}}
                  <ResponsivePhoto @photo={{@teamseason.photo}} @top={{@teamseason.photo_top}} @bottom={{@teamseason.photo_bottom}} @maxHeight={{120}} @style="teamphoto_index" class="responsive-image team-photo-block-image" />
              {{/if}}
          </div>
          <div class="card-body">
              <span class="text-black">{{ @teamseason.team.name }}</span>
          </div>
      </div>
  </LinkTo>
  */
  {
    "id": "lt0etSmQ",
    "block": "{\"symbols\":[\"@teamseason\"],\"statements\":[[8,\"link-to\",[[24,0,\"team-card\"]],[[\"@route\",\"@model\",\"@query\"],[\"team.show\",[32,1,[\"team\",\"code\"]],[30,[36,0],null,[[\"season\"],[null]]]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[10,\"div\"],[14,0,\"card\"],[12],[2,\"\\n        \"],[10,\"div\"],[14,0,\"mdl-card__title team-photo-block\"],[12],[2,\"\\n            \"],[10,\"div\"],[14,0,\"team-photo-block-text\"],[12],[1,[32,1,[\"team\",\"code\"]]],[13],[2,\"\\n\"],[6,[37,1],[[32,1,[\"photo\"]]],null,[[\"default\"],[{\"statements\":[[2,\"                \"],[8,\"responsive-photo\",[[24,0,\"responsive-image team-photo-block-image\"]],[[\"@photo\",\"@top\",\"@bottom\",\"@maxHeight\",\"@style\"],[[32,1,[\"photo\"]],[32,1,[\"photo_top\"]],[32,1,[\"photo_bottom\"]],120,\"teamphoto_index\"]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"        \"],[13],[2,\"\\n        \"],[10,\"div\"],[14,0,\"card-body\"],[12],[2,\"\\n            \"],[10,\"span\"],[14,0,\"text-black\"],[12],[1,[32,1,[\"team\",\"name\"]]],[13],[2,\"\\n        \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"hash\",\"if\"]}",
    "meta": {
      "moduleName": "ev/components/team/index-card.hbs"
    }
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});
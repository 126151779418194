define("ev/utils/rewrite-drupal-url", ["exports", "url-parse"], function (_exports, _urlParse) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = rewriteDrupalUrl;

  function rewriteDrupalUrl(url, style) {
    var parser = new _urlParse.default(url);

    if (parser.protocol === "drupal:") {
      return "https://www.erasmusvolley.nl/node/" + parser.hostname + "/image/" + (parser.pathname || "").replace(/^\/|\/$/g, "") + "/" + style;
    } else if (parser.hostname === "erasmusvolley.nl" || parser.hostname === "www.erasmusvolley.nl") {
      return url.replace(/^\/|\/$/g, "") + "/" + style;
    }

    return url;
  }
});
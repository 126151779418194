define("ev/templates/documents", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "HbgcVeMx",
    "block": "{\"symbols\":[],\"statements\":[[1,[30,[36,1],[[30,[36,0],[\"documents.title\"],null]],null]],[2,\"\\n\\n\"],[1,[30,[36,3],[[30,[36,2],null,null]],null]]],\"hasEval\":false,\"upvars\":[\"t\",\"page-title\",\"-outlet\",\"component\"]}",
    "meta": {
      "moduleName": "ev/templates/documents.hbs"
    }
  });

  _exports.default = _default;
});
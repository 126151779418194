define("ev/initializers/season", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    name: "season",
    initialize: function initialize(app) {
      app.__container__.lookup("service:season");
    }
  };
  _exports.default = _default;
});
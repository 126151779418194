define("ev/templates/committee/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "O2QHVlrs",
    "block": "{\"symbols\":[\"committee\",\"committee\"],\"statements\":[[1,[30,[36,1],[[30,[36,0],[\"committee.title\"],null]],null]],[2,\"\\n\\n\"],[10,\"div\"],[14,0,\"teams-section teams-mens\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"row hidden-sm-down\"],[12],[2,\"\\n\"],[6,[37,3],[[30,[36,2],[[30,[36,2],[[32,0,[\"committees\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"            \"],[10,\"div\"],[14,0,\"col-md-3\"],[12],[2,\"\\n                \"],[8,\"committee/index-card\",[],[[\"@committee\"],[[32,2]]],null],[2,\"\\n            \"],[13],[2,\"\\n\"]],\"parameters\":[2]}]]],[2,\"    \"],[13],[2,\"\\n    \"],[10,\"table\"],[14,0,\"table hidden-md-up\"],[12],[2,\"\\n\"],[6,[37,3],[[30,[36,2],[[30,[36,2],[[32,0,[\"committees\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"            \"],[10,\"tr\"],[12],[2,\"\\n                \"],[10,\"td\"],[12],[2,\"\\n                    \"],[8,\"link-to\",[],[[\"@route\",\"@model\"],[\"committee.show\",[32,1,[\"committee\",\"slug\"]]]],[[\"default\"],[{\"statements\":[[1,[32,1,[\"committee\",\"name\"]]]],\"parameters\":[]}]]],[2,\"\\n                \"],[13],[2,\"\\n            \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"    \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"t\",\"page-title\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "ev/templates/committee/index.hbs"
    }
  });

  _exports.default = _default;
});
define("ev/templates/team/show/results", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "yJgdTS/y",
    "block": "{\"symbols\":[\"teamgroup\"],\"statements\":[[6,[37,2],[[30,[36,1],[[30,[36,1],[[35,0,[\"teamseason\",\"groups\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"\\t\"],[10,\"h4\"],[14,0,\"section-header\"],[12],[1,[32,1,[\"group\",\"fullname\"]]],[13],[2,\"\\n\\t\"],[8,\"team/teamgroup-standings\",[],[[\"@teamId\",\"@matches\",\"@group\"],[[34,0,[\"id\"]],[34,0,[\"matches\"]],[32,1,[\"group\"]]]],null],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"model\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "ev/templates/team/show/results.hbs"
    }
  });

  _exports.default = _default;
});
define("ev/initializers/inflector", ["exports", "ember-inflector"], function (_exports, _emberInflector) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;

  function
    /* application */
  initialize() {
    var inflector = _emberInflector.default.inflector;
    inflector.uncountable("court");
    inflector.uncountable("event");
    inflector.uncountable("hall");
    inflector.uncountable("match");
    inflector.uncountable("member");
    inflector.uncountable("standing");
    inflector.uncountable("team");
    inflector.uncountable("training");
    inflector.uncountable("announcement");
    inflector.uncountable("committee");
  }

  var _default = {
    name: "inflector",
    initialize: initialize
  };
  _exports.default = _default;
});
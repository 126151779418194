define("ev/templates/training/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "gPzMOBhD",
    "block": "{\"symbols\":[\"team\",\"date\",\"date\"],\"statements\":[[1,[30,[36,5],[[30,[36,4],[\"trainings.title\"],null]],null]],[2,\"\\n\\n\"],[10,\"div\"],[14,0,\"mt-3\"],[12],[2,\"\\n\\t\"],[10,\"div\"],[14,0,\"table-responsive\"],[12],[2,\"\\n\\t\\t\"],[10,\"table\"],[14,0,\"table table-striped\"],[12],[2,\"\\n\\t\\t\\t\"],[10,\"thead\"],[12],[2,\"\\n\\t\\t\\t\"],[10,\"tr\"],[12],[2,\"\\n\\t\\t\\t\\t\"],[10,\"th\"],[12],[2,\"Teams\"],[13],[2,\"\\n\"],[6,[37,2],[[30,[36,1],[[30,[36,1],[[35,0]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"\\t\\t\\t\\t\\t\"],[10,\"th\"],[12],[1,[30,[36,3],[[32,3],\"D MMM\"],null]],[13],[2,\"\\n\"]],\"parameters\":[3]}]]],[2,\"\\t\\t\\t\"],[13],[2,\"\\n\\t\\t\\t\"],[13],[2,\"\\n\\t\\t\\t\"],[10,\"tbody\"],[12],[2,\"\\n\"],[6,[37,2],[[30,[36,1],[[30,[36,1],[[35,6]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"\\t\\t\\t\\t\"],[10,\"tr\"],[12],[2,\"\\n\\t\\t\\t\\t\\t\"],[10,\"th\"],[12],[8,\"link-to\",[],[[\"@route\",\"@model\"],[\"team.show\",[32,1,[\"value\",\"code\"]]]],[[\"default\"],[{\"statements\":[[1,[32,1,[\"value\",\"code\"]]]],\"parameters\":[]}]]],[13],[2,\"\\n\"],[6,[37,2],[[30,[36,1],[[30,[36,1],[[35,0]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"\\t\\t\\t\\t\\t\\t\"],[10,\"td\"],[12],[8,\"training/ev-training\",[],[[\"@mode\",\"@data\",\"@date\"],[\"team\",[32,1],[32,2]]],null],[13],[2,\"\\n\"]],\"parameters\":[2]}]]],[2,\"\\t\\t\\t\\t\"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\t\\t\\t\"],[13],[2,\"\\n\\t\\t\"],[13],[2,\"\\n\\t\"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"dates\",\"-track-array\",\"each\",\"moment-format\",\"t\",\"page-title\",\"teams\"]}",
    "meta": {
      "moduleName": "ev/templates/training/index.hbs"
    }
  });

  _exports.default = _default;
});
define("ev/templates/team/show/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "6TLbQhJ9",
    "block": "{\"symbols\":[\"teamgroup\",\"event\"],\"statements\":[[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n\\n\\t\"],[10,\"div\"],[14,0,\"col-md-8 col-12\"],[12],[2,\"\\n\"],[6,[37,5],[[30,[36,4],[[30,[36,4],[[35,3]],null]],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"\\t\\t\\t\"],[8,\"ev-event-card\",[],[[\"@event\",\"@officials\",\"@showDetailsForTraining\",\"@supportCompact\"],[[32,2],[32,2,[\"officials\"]],[34,2,[\"is_own_player\"]],false]],null],[2,\"\\n\"]],\"parameters\":[2]},{\"statements\":[[2,\"\\t\\t\\t\"],[10,\"div\"],[14,0,\"alert alert-info text-center\"],[12],[2,\"\\n\\t\\t\\t\\t\"],[1,[30,[36,1],[\"team.no_events_overview\"],[[\"team\"],[[35,0,[\"name\"]]]]]],[2,\"\\n\\t\\t\\t\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\t\"],[13],[2,\"\\n\\t\"],[10,\"div\"],[14,0,\"col-md-4 col-12\"],[12],[2,\"\\n\"],[6,[37,5],[[30,[36,4],[[30,[36,4],[[35,0,[\"teamseason\",\"groups\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"\\t\\t\\t\"],[10,\"p\"],[14,0,\"section-header\"],[12],[1,[32,1,[\"group\",\"group\"]]],[13],[2,\"\\n\\t\\t\\t\"],[8,\"team/teamgroup-standings-summary\",[],[[\"@group\",\"@teamId\"],[[32,1,[\"group\"]],[34,0,[\"id\"]]]],null],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\t\"],[13],[2,\"\\n\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"model\",\"t\",\"teamController\",\"events\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "ev/templates/team/show/index.hbs"
    }
  });

  _exports.default = _default;
});
define("ev/templates/errors/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "JdTsx473",
    "block": "{\"symbols\":[],\"statements\":[[10,\"h3\"],[12],[2,\"Ouch!\"],[13],[2,\"\\n\"],[10,\"p\"],[14,0,\"whoops\"],[12],[2,\"Whoops, something unexpected happened, and this broke Erasmus Volley Play.\"],[13],[2,\"\\n\"],[10,\"p\"],[12],[8,\"link-to\",[],[[\"@route\"],[\"index\"]],[[\"default\"],[{\"statements\":[[2,\"Return to Dashboard\"]],\"parameters\":[]}]]],[13]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "ev/templates/errors/index.hbs"
    }
  });

  _exports.default = _default;
});
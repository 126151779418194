define("ev/helpers/fa", ["exports", "@fortawesome/fontawesome-svg-core"], function (_exports, _fontawesomeSvgCore) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.iconSvg = iconSvg;
  _exports.renderedIcon = renderedIcon;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function _iterableToArrayLimit(arr, i) { var _i = arr == null ? null : typeof Symbol !== "undefined" && arr[Symbol.iterator] || arr["@@iterator"]; if (_i == null) return; var _arr = []; var _n = true; var _d = false; var _s, _e; try { for (_i = _i.call(arr); !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function renderedIcon(iconObject, options) {
    var _faClasses;

    var _ref = options || {},
        _ref$spin = _ref.spin,
        spin = _ref$spin === void 0 ? false : _ref$spin,
        _ref$pulse = _ref.pulse,
        pulse = _ref$pulse === void 0 ? false : _ref$pulse,
        _ref$fixedWidth = _ref.fixedWidth,
        fixedWidth = _ref$fixedWidth === void 0 ? false : _ref$fixedWidth,
        _ref$border = _ref.border,
        border = _ref$border === void 0 ? false : _ref$border,
        _ref$listItem = _ref.listItem,
        listItem = _ref$listItem === void 0 ? false : _ref$listItem,
        _ref$flip = _ref.flip,
        flip = _ref$flip === void 0 ? false : _ref$flip,
        _ref$size = _ref.size,
        size = _ref$size === void 0 ? null : _ref$size,
        _ref$rotation = _ref.rotation,
        rotation = _ref$rotation === void 0 ? null : _ref$rotation,
        _ref$pull = _ref.pull,
        pull = _ref$pull === void 0 ? null : _ref$pull,
        _ref$transform = _ref.transform,
        transformProp = _ref$transform === void 0 ? null : _ref$transform,
        _ref$symbol = _ref.symbol,
        symbol = _ref$symbol === void 0 ? null : _ref$symbol,
        _ref$mask = _ref.mask,
        mask = _ref$mask === void 0 ? null : _ref$mask,
        _ref$classNames = _ref.classNames,
        classNames = _ref$classNames === void 0 ? '' : _ref$classNames,
        _ref$title = _ref.title,
        title = _ref$title === void 0 ? null : _ref$title;

    if (!iconObject) {
      return null;
    }

    var faClasses = (_faClasses = {
      'fa-spin': spin,
      'fa-pulse': pulse,
      'fa-fw': fixedWidth,
      'fa-border': border,
      'fa-li': listItem,
      'fa-flip-horizontal': flip === 'horizontal' || flip === 'both',
      'fa-flip-vertical': flip === 'vertical' || flip === 'both'
    }, _defineProperty(_faClasses, "fa-".concat(size), size !== null), _defineProperty(_faClasses, "fa-rotate-".concat(rotation), rotation !== null), _defineProperty(_faClasses, "fa-pull-".concat(pull), pull !== null), _faClasses);
    var classes = Object.keys(faClasses).map(function (key) {
      return faClasses[key] ? key : null;
    }).filter(function (key) {
      return key;
    }).concat(classNames.split(' ').filter(function (c) {
      return !c.match(/^fa-/);
    }));
    var transform = typeof transformProp === 'string' ? _fontawesomeSvgCore.parse.transform(transformProp) : transformProp;
    var o = {
      classes: classes,
      transform: transform,
      mask: mask,
      symbol: symbol,
      title: title ? "".concat(title) : null
    };
    var newIcon = (0, _fontawesomeSvgCore.icon)(iconObject, o);

    if (!newIcon) {
      console.warn("Could not find icon: icon=".concat(iconObject));
      return null;
    }

    return newIcon;
  }

  function iconSvg(iconObject, options) {
    var newIcon = renderedIcon(iconObject, options);

    if (!newIcon) {
      return null;
    }

    var abstractIcon = newIcon.abstract[0];
    var attributes = abstractIcon.attributes;
    var stringAttributes = Object.keys(attributes).map(function (k) {
      return "".concat(k, "=\"").concat(attributes[k], "\"");
    }).join(" ");
    return Ember.String.htmlSafe("<svg ".concat(stringAttributes, ">").concat(abstractIcon.children.reduce(function (acc, cur) {
      return "".concat(acc).concat((0, _fontawesomeSvgCore.toHtml)(cur));
    }, ''), "</svg>"));
  }

  var _default = Ember.Helper.helper(function (_ref2, options) {
    var _ref3 = _slicedToArray(_ref2, 1),
        iconObject = _ref3[0];

    return iconSvg(iconObject, options);
  });

  _exports.default = _default;
});
define("ev/controllers/profile/show/about", ["exports", "moment", "jquery"], function (_exports, _moment, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function getDistanceFromLatLonInKm(lat1, lon1, lat2, lon2) {
    var R = 6371; // Radius of the earth in km

    var dLat = deg2rad(lat2 - lat1); // deg2rad below

    var dLon = deg2rad(lon2 - lon1);
    var a = Math.sin(dLat / 2) * Math.sin(dLat / 2) + Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) * Math.sin(dLon / 2) * Math.sin(dLon / 2);
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    var d = R * c; // Distance in km

    return d;
  }

  function deg2rad(deg) {
    return deg * (Math.PI / 180);
  }

  function round(value, decimals) {
    return Number(Math.round(value + "e" + decimals) + "e-" + decimals);
  }

  var _default = Ember.Controller.extend({
    parentController: Ember.inject.controller("profile/show"),
    ajax: Ember.inject.service("ajax-ns"),
    age: Ember.computed("model.dob", function () {
      return (0, _moment.default)().diff(this.get("model.dob"), "years");
    }),
    distance: Ember.computed("model.origin_location", function () {
      var evLat = 51.91797490,
          evLon = 4.52617410,
          userLat = this.get("model.origin_location.lat"),
          userLon = this.get("model.origin_location.lon");
      return round(getDistanceFromLatLonInKm(evLat, evLon, userLat, userLon), 0);
    }),
    mapOptions: {
      type: "terrain",
      minZoom: 2,
      maxZoom: 15,
      zoomControl: true,
      streetViewControl: false,
      rotateControl: false,
      panControl: false,
      scrollwheel: false,
      mapTypeControl: false,
      styles: []
    },
    actions: {
      placeChanged: function placeChanged(place) {
        if (place.geometry && place.geometry.location) {
          var model = this.model;
          var components = Ember.A(place.address_components);
          var locality = Ember.get(components.filter(function (component) {
            return component.types.indexOf("locality") !== -1;
          }), "0.long_name");
          var country = Ember.get(components.filter(function (component) {
            return component.types.indexOf("country") !== -1;
          }), "0.short_name");
          this.ajax.put("me/profile", {
            data: {
              origin_latitude: place.geometry.location.lat(),
              origin_longitude: place.geometry.location.lng(),
              origin_name: locality,
              origin_country: country
            }
          }).then(function () {
            model.set("origin_location", {
              lat: place.geometry.location.lat(),
              lon: place.geometry.location.lng()
            });
            var components = Ember.A(place.address_components);
            var locality = Ember.get(components.filter(function (component) {
              return component.types.indexOf("locality") !== -1;
            }), "0.long_name");
            var country = Ember.get(components.filter(function (component) {
              return component.types.indexOf("country") !== -1;
            }), "0.short_name");
            model.set("origin_name", locality);
            model.set("origin_country", country);
            (0, _jquery.default)(".card-profile-origin .badge-saved").show(500);
            setTimeout(function () {
              (0, _jquery.default)(".card-profile-origin .badge-saved").hide(500);
            }, 3000);
          });
        }
      }
    }
  });

  _exports.default = _default;
});
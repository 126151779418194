define("ev/helpers/ev-highlight-match-teams", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.highlightMatchTeams = highlightMatchTeams;

  function highlightMatchTeams(value) {
    var className = value[0];
    var leftSide = value[1];

    for (var i = 2; i < value.length; i++) {
      if (leftSide === value[i]) {
        return Ember.String.htmlSafe(className);
      }
    }

    return Ember.String.htmlSafe("");
  }

  var _default = Ember.Helper.helper(highlightMatchTeams);

  _exports.default = _default;
});
define("ev/utils/group-by", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = groupBy;

  function groupBy(collection, property) {
    var groups = Ember.A();
    var items = collection;
    items.forEach(function (item) {
      var value = Ember.get(item, property);
      var group = groups.findBy("value", value);

      if (Ember.isPresent(group)) {
        Ember.get(group, "items").push(item);
      } else {
        group = {
          property: property,
          value: value,
          items: [item]
        };
        groups.push(group);
      }
    });
    return groups;
  }
});
define("ev/templates/training/_topnav", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ZoyBfotW",
    "block": "{\"symbols\":[],\"statements\":[[10,\"li\"],[14,0,\"nav-item\"],[12],[2,\"\\n\\t\"],[8,\"link-to\",[[24,0,\"nav-link\"]],[[\"@route\",\"@replace\"],[\"training.index\",true]],[[\"default\"],[{\"statements\":[[2,\"Teams\"]],\"parameters\":[]}]]],[2,\"\\n\"],[13],[2,\"\\n\"],[10,\"li\"],[14,0,\"nav-item\"],[12],[2,\"\\n\\t\"],[8,\"link-to\",[[24,0,\"nav-link\"]],[[\"@route\",\"@replace\"],[\"training.trainers\",true]],[[\"default\"],[{\"statements\":[[2,\"Trainers\"]],\"parameters\":[]}]]],[2,\"\\n\"],[13],[2,\"\\n\"],[6,[37,2],[[35,1,[\"user\",\"is_trainer\"]]],null,[[\"default\"],[{\"statements\":[[2,\"\\t\"],[10,\"li\"],[14,0,\"nav-item\"],[12],[2,\"\\n\\t\\t\"],[8,\"link-to\",[[24,0,\"nav-link\"]],[[\"@route\"],[\"training.availability\"]],[[\"default\"],[{\"statements\":[[1,[30,[36,0],[\"trainings.availability.title\"],null]]],\"parameters\":[]}]]],[2,\"\\n\\t\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"t\",\"currentUser\",\"if\"]}",
    "meta": {
      "moduleName": "ev/templates/training/_topnav.hbs"
    }
  });

  _exports.default = _default;
});
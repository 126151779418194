define("ev/helpers/in-array", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.helper(function (params) {
    var needle = params[0];
    var haystack = Array.isArray(params[1]) ? params[1] : params.slice(1);
    return haystack.indexOf(needle) !== -1;
  });

  _exports.default = _default;
});
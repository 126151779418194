define("ev/templates/errors/no-connection", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "24EIV4d9",
    "block": "{\"symbols\":[],\"statements\":[[10,\"h3\"],[12],[2,\"No Connection\"],[13],[2,\"\\n\"],[10,\"p\"],[14,0,\"whoops\"],[12],[2,\"Sorry, but we're having trouble connecting to the server. This problem is usually the result of a broken Internet connection. You can try refreshing this page.\"],[13],[2,\"\\n\"],[10,\"p\"],[12],[2,\"\\n    \"],[8,\"link-to\",[],[[\"@route\"],[\"index\"]],[[\"default\"],[{\"statements\":[[2,\"Return to Dashboard\"]],\"parameters\":[]}]]],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "ev/templates/errors/no-connection.hbs"
    }
  });

  _exports.default = _default;
});
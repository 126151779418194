define("ev/components/tip", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <ConditionalLinkTo @asLink={{if @tip.link true false}} @route={{@tip.link}}>
      <div class="tip card">
          <div class="card-body">
              <div class="light">
                  <div class="lamp"><span class="fila"></span></div>
                  <div class="redo"></div>
                  <div class="base"><span class="base3"></span></div>
              </div>
              <div class="tip-content">
                  <h6 class="text-muted">Tip!</h6>
                  <p>{{ t @tip.text }}</p>
              </div>
          </div>
      </div>
  </ConditionalLinkTo>
  */
  {
    "id": "SeInPgmp",
    "block": "{\"symbols\":[\"@tip\"],\"statements\":[[8,\"conditional-link-to\",[],[[\"@asLink\",\"@route\"],[[30,[36,0],[[32,1,[\"link\"]],true,false],null],[32,1,[\"link\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[10,\"div\"],[14,0,\"tip card\"],[12],[2,\"\\n        \"],[10,\"div\"],[14,0,\"card-body\"],[12],[2,\"\\n            \"],[10,\"div\"],[14,0,\"light\"],[12],[2,\"\\n                \"],[10,\"div\"],[14,0,\"lamp\"],[12],[10,\"span\"],[14,0,\"fila\"],[12],[13],[13],[2,\"\\n                \"],[10,\"div\"],[14,0,\"redo\"],[12],[13],[2,\"\\n                \"],[10,\"div\"],[14,0,\"base\"],[12],[10,\"span\"],[14,0,\"base3\"],[12],[13],[13],[2,\"\\n            \"],[13],[2,\"\\n            \"],[10,\"div\"],[14,0,\"tip-content\"],[12],[2,\"\\n                \"],[10,\"h6\"],[14,0,\"text-muted\"],[12],[2,\"Tip!\"],[13],[2,\"\\n                \"],[10,\"p\"],[12],[1,[30,[36,1],[[32,1,[\"text\"]]],null]],[13],[2,\"\\n            \"],[13],[2,\"\\n        \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"if\",\"t\"]}",
    "meta": {
      "moduleName": "ev/components/tip.hbs"
    }
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});
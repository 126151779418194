define("ev/components/pardoen/dashboard-card", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="card card-pardoen">
      <div class="card-body">
          <h4 class="card-title">
              <FaIcon @icon="beer" @fixedWidth={{true}} /> {{ t "pardoen.title" }}</h4>
          <p>{{ t "pardoen.description" }}</p>
          <div class="d-flex justify-content-between card-actions">
              <div class="card-action">
                  <LinkTo @route="pardoen" class="text-white">{{ t "pardoen.seemore" }}</LinkTo>
              </div>
          </div>
      </div>
  </div>
  */
  {
    "id": "z+Xn96CW",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"card card-pardoen\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"card-body\"],[12],[2,\"\\n        \"],[10,\"h4\"],[14,0,\"card-title\"],[12],[2,\"\\n            \"],[8,\"fa-icon\",[],[[\"@icon\",\"@fixedWidth\"],[\"beer\",true]],null],[2,\" \"],[1,[30,[36,0],[\"pardoen.title\"],null]],[13],[2,\"\\n        \"],[10,\"p\"],[12],[1,[30,[36,0],[\"pardoen.description\"],null]],[13],[2,\"\\n        \"],[10,\"div\"],[14,0,\"d-flex justify-content-between card-actions\"],[12],[2,\"\\n            \"],[10,\"div\"],[14,0,\"card-action\"],[12],[2,\"\\n                \"],[8,\"link-to\",[[24,0,\"text-white\"]],[[\"@route\"],[\"pardoen\"]],[[\"default\"],[{\"statements\":[[1,[30,[36,0],[\"pardoen.seemore\"],null]]],\"parameters\":[]}]]],[2,\"\\n            \"],[13],[2,\"\\n        \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"t\"]}",
    "meta": {
      "moduleName": "ev/components/pardoen/dashboard-card.hbs"
    }
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});
define("ev/templates/training/availability", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "/fv4CU4X",
    "block": "{\"symbols\":[\"month\",\"date\"],\"statements\":[[1,[30,[36,4],[[30,[36,3],[\"trainings.availability.title\"],null]],null]],[2,\"\\n\\n\"],[10,\"div\"],[14,0,\"mt-3\"],[12],[2,\"\\n\\t\"],[10,\"div\"],[14,0,\"container\"],[12],[2,\"\\n\"],[6,[37,2],[[30,[36,1],[[30,[36,1],[[32,0,[\"groupedByMonth\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"\\t\\t\\t\"],[10,\"h4\"],[12],[1,[30,[36,0],[[32,1,[\"value\"]],\"MMMM\"],null]],[13],[2,\"\\n\\t\\t\\t\"],[10,\"div\"],[14,0,\"row mb-4\"],[12],[2,\"\\n\"],[6,[37,2],[[30,[36,1],[[30,[36,1],[[32,1,[\"items\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"\\t\\t\\t\\t\\t\"],[10,\"div\"],[14,0,\"col-md-4\"],[12],[2,\"\\n\\t\\t\\t\\t\\t\\t\"],[8,\"training/availability-card\",[],[[\"@date\"],[[32,2]]],null],[2,\"\\n\\t\\t\\t\\t\\t\"],[13],[2,\"\\n\"]],\"parameters\":[2]}]]],[2,\"\\t\\t\\t\"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\t\"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"moment-format\",\"-track-array\",\"each\",\"t\",\"page-title\"]}",
    "meta": {
      "moduleName": "ev/templates/training/availability.hbs"
    }
  });

  _exports.default = _default;
});
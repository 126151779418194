define("ev/templates/landing/signin/right", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "bi4eKOK+",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"right-wrapper\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,5,\"max-width: 600px; margin: 0 auto;\"],[12],[2,\"\\n        \"],[10,\"div\"],[14,0,\"d-flex sponsorkliks-signin align-items-center\"],[12],[2,\"\\n            \"],[10,\"div\"],[14,0,\"mr-3\"],[12],[1,[30,[36,0],[\"sponsor.description\"],null]],[2,\"\\n                \"],[10,\"a\"],[14,6,\"https://www.sponsorkliks.com/products/shops.php?club=3853\"],[14,\"target\",\"_blank\"],[14,\"rel\",\"noreferrer noopener\"],[14,5,\"font-weight: bold;\"],[12],[1,[30,[36,0],[\"sponsor.shopnow\"],null]],[2,\" »\"],[13],[2,\"\\n            \"],[13],[2,\"\\n            \"],[10,\"div\"],[12],[2,\"\\n                \"],[10,\"div\"],[14,0,\"d-flex flex-column flex-sm-row\"],[12],[2,\"\\n                    \"],[10,\"div\"],[14,0,\"sponsorkliks-link mr-sm-2 mb-1 mb-sm-0\"],[12],[2,\"\\n                        \"],[10,\"a\"],[14,6,\"https://www.sponsorkliks.com/link.php?club=3853&shop_id=4&shop=Thuisbezorgd.nl\"],[14,\"target\",\"_blank\"],[14,\"rel\",\"noreferrer noopener\"],[12],[10,\"img\"],[14,\"src\",\"/images/tb.png\"],[14,0,\"img-fluid\"],[12],[13],[13],[2,\"\\n                    \"],[13],[2,\"\\n                    \"],[10,\"div\"],[14,0,\"sponsorkliks-link\"],[12],[2,\"\\n                        \"],[10,\"a\"],[14,6,\"https://www.sponsorkliks.com/products/shops.php?club=3853\"],[14,\"target\",\"_blank\"],[14,\"rel\",\"noreferrer noopener\"],[12],[10,\"img\"],[14,\"src\",\"/images/sk-dark.gif\"],[14,0,\"img-fluid\"],[12],[13],[13],[2,\"\\n                    \"],[13],[2,\"\\n                \"],[13],[2,\"\\n            \"],[13],[2,\"\\n        \"],[13],[2,\"\\n        \"],[10,\"hr\"],[12],[13],[2,\"\\n        \"],[8,\"ev-index-upcoming\",[],[[\"@columnised\"],[false]],null],[2,\"\\n    \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"t\"]}",
    "meta": {
      "moduleName": "ev/templates/landing/signin/right.hbs"
    }
  });

  _exports.default = _default;
});
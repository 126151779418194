define("ev/templates/loading", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "obFgIOa3",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"loading-spinkit centred\"],[12],[2,\"\\n\\t\"],[8,\"spinkit-chasing-dots\",[],[[],[]],null],[2,\"\\n\\n    \"],[10,\"div\"],[14,0,\"loading-text\"],[12],[2,\"\\n        \"],[10,\"h2\"],[14,0,\"loading-title\"],[12],[2,\"Loading...\"],[13],[2,\"\\n        \"],[10,\"p\"],[14,0,\"loading-message\"],[12],[1,[32,0,[\"loadingText\"]]],[13],[2,\"\\n    \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "ev/templates/loading.hbs"
    }
  });

  _exports.default = _default;
});
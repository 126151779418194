define("ev/components/profile/index-card", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <tr>
      <td>{{ @member.name }}
          {{#if @member.is_honorary}}
              <FaIcon @icon="crown"/>
          {{else if @member.is_merit}}
              <FaIcon @icon="ribbon"/>
          {{/if}}
      </td>
  </tr>
  
  */
  {
    "id": "OUxmw+OE",
    "block": "{\"symbols\":[\"@member\"],\"statements\":[[10,\"tr\"],[12],[2,\"\\n    \"],[10,\"td\"],[12],[1,[32,1,[\"name\"]]],[2,\"\\n\"],[6,[37,0],[[32,1,[\"is_honorary\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"            \"],[8,\"fa-icon\",[],[[\"@icon\"],[\"crown\"]],null],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,0],[[32,1,[\"is_merit\"]]],null,[[\"default\"],[{\"statements\":[[2,\"            \"],[8,\"fa-icon\",[],[[\"@icon\"],[\"ribbon\"]],null],[2,\"\\n        \"]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[2,\"    \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"if\"]}",
    "meta": {
      "moduleName": "ev/components/profile/index-card.hbs"
    }
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});
define("ev/helpers/t-array", ["exports", "ember-intl/helpers/t"], function (_exports, _t) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _t.default.extend({
    format: function format(prefix) {
      var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      return this.intl.array(prefix, options);
    }
  });

  _exports.default = _default;
});
define("ev/templates/pardoen/_topnav", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "L0DuAY+C",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,2],[[30,[36,1],[[32,0,[\"totalPurchases\"]],0],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"li\"],[14,0,\"nav-item\"],[12],[2,\"\\n        \"],[8,\"link-to\",[[24,0,\"nav-link\"]],[[\"@route\"],[\"pardoen.index\"]],[[\"default\"],[{\"statements\":[[1,[30,[36,0],[\"pardoen.competition\"],null]],[2,\"\\n            \"],[10,\"span\"],[14,0,\"badge badge-light\"],[12],[1,[32,0,[\"totalPurchases\"]]],[13]],\"parameters\":[]}]]],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[6,[37,2],[[30,[36,1],[[32,0,[\"totalPurchasesSummer\"]],0],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"li\"],[14,0,\"nav-item\"],[12],[2,\"\\n        \"],[8,\"link-to\",[[24,0,\"nav-link\"]],[[\"@route\"],[\"pardoen.summer\"]],[[\"default\"],[{\"statements\":[[1,[30,[36,0],[\"pardoen.summer\"],null]],[2,\"\\n            \"],[10,\"span\"],[14,0,\"badge badge-light\"],[12],[1,[32,0,[\"totalPurchasesSummer\"]]],[13]],\"parameters\":[]}]]],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[10,\"li\"],[14,0,\"nav-item\"],[12],[2,\"\\n    \"],[8,\"link-to\",[[24,0,\"nav-link\"]],[[\"@route\"],[\"pardoen.me\"]],[[\"default\"],[{\"statements\":[[1,[30,[36,0],[\"pardoen.me\"],null]],[2,\"\\n        \"],[10,\"span\"],[14,0,\"badge badge-light\"],[12],[1,[32,0,[\"totalPurchasesMember\"]]],[13]],\"parameters\":[]}]]],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"t\",\"gt\",\"if\"]}",
    "meta": {
      "moduleName": "ev/templates/pardoen/_topnav.hbs"
    }
  });

  _exports.default = _default;
});
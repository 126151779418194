define("ev/templates/profile/index/map", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "92xQuoxV",
    "block": "{\"symbols\":[\"context\",\"member\"],\"statements\":[[1,[30,[36,3],[[30,[36,2],[\"profile.title\"],null]],null]],[2,\"\\n\\n\"],[10,\"div\"],[14,0,\"g-map-wrapper\"],[12],[2,\"\\n\\t\"],[8,\"g-map\",[],[[\"@options\",\"@markersFitMode\"],[[34,4],\"live\"]],[[\"default\"],[{\"statements\":[[2,\"\\n\\t\\t\"],[1,[30,[36,0],[[32,1]],[[\"lat\",\"lng\",\"icon\",\"zIndex\"],[51.9179749,4.5261741,\"/images/marker-volleyball.png\",2]]]],[2,\"\\n\"],[6,[37,7],[[30,[36,6],[[30,[36,6],[[35,5]],null]],null]],null,[[\"default\"],[{\"statements\":[[6,[37,1],[[32,2,[\"origin_location\"]]],null,[[\"default\"],[{\"statements\":[[2,\"\\t\\t\\t\\t\"],[1,[30,[36,0],[[32,1]],[[\"lat\",\"lng\",\"icon\",\"zIndex\"],[[32,2,[\"origin_location\",\"lat\"]],[32,2,[\"origin_location\",\"lon\"]],\"/images/marker-home.png\",1]]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[2]}]]],[2,\"\\t\"]],\"parameters\":[1]}]]],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"g-map-marker\",\"if\",\"t\",\"page-title\",\"mapOptions\",\"model\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "ev/templates/profile/index/map.hbs"
    }
  });

  _exports.default = _default;
});
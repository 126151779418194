define("ev/helpers/ev-dict-access", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.dictAccess = dictAccess;

  function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }

  function dictAccess(value) {
    var dictionary = value[0];

    for (var i = 1; i < value.length; i++) {
      if (dictionary === null || _typeof(dictionary) !== "object") {
        return null;
      } else if (dictionary.hasOwnProperty(value[i])) {
        dictionary = dictionary[value[i]];
      } else if (dictionary.hasOwnProperty("get")) {
        dictionary = dictionary.get(value[i]);
      } else {
        return null;
      }
    }

    return dictionary;
  }

  var _default = Ember.Helper.helper(dictAccess);

  _exports.default = _default;
});
define("ev/templates/pardoen/summer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "t8ULBPMM",
    "block": "{\"symbols\":[\"committee\",\"member\"],\"statements\":[[10,\"div\"],[14,0,\"container standings-content\"],[12],[2,\"\\n\\n    \"],[10,\"div\"],[14,0,\"row mb-3\"],[12],[2,\"\\n\"],[6,[37,2],[[35,1,[\"members\"]]],null,[[\"default\"],[{\"statements\":[[2,\"            \"],[10,\"div\"],[14,0,\"col-lg-6\"],[12],[2,\"\\n                \"],[8,\"pardoen/ranking\",[],[[\"@title\",\"@more\",\"@items\",\"@modalMax\",\"@groupBy\"],[[30,[36,0],[\"standings.barcards.ten_members\"],null],[30,[36,0],[\"standings.barcards.more_members\"],null],[32,0,[\"model\",\"members\"]],10,[32,0,[\"keyName\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n                    \"],[8,\"conditional-link-to\",[],[[\"@asLink\",\"@route\",\"@model\"],[[32,2,[\"privacy\",\"full\"]],\"profile.show\",[32,2,[\"id\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n                        \"],[1,[32,2,[\"name\"]]],[2,\"\\n                    \"]],\"parameters\":[]}]]],[2,\"\\n                \"]],\"parameters\":[2]}]]],[2,\"\\n            \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[6,[37,2],[[35,1,[\"committees\"]]],null,[[\"default\"],[{\"statements\":[[2,\"            \"],[10,\"div\"],[14,0,\"col-lg-6\"],[12],[2,\"\\n                \"],[8,\"pardoen/ranking\",[],[[\"@title\",\"@more\",\"@items\",\"@groupBy\"],[[30,[36,0],[\"standings.barcards.committees\"],null],[30,[36,0],[\"standings.barcards.more_committees\"],null],[32,0,[\"model\",\"committees\"]],[32,0,[\"keyName\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n                    \"],[1,[32,1,[\"name\"]]],[2,\"\\n                \"]],\"parameters\":[1]}]]],[2,\"\\n            \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"    \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"t\",\"model\",\"if\"]}",
    "meta": {
      "moduleName": "ev/templates/pardoen/summer.hbs"
    }
  });

  _exports.default = _default;
});
define("ev/templates/components/spinkit-chasing-dots", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "QIJ2x9E0",
    "block": "{\"symbols\":[],\"statements\":[[2,\"\\n \\n\\n      \"],[10,\"div\"],[14,0,\"sk-chasing-dots\"],[12],[2,\"\\n        \"],[10,\"div\"],[14,0,\"sk-child sk-dot1\"],[12],[13],[2,\"\\n        \"],[10,\"div\"],[14,0,\"sk-child sk-dot2\"],[12],[13],[2,\"\\n      \"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "ev/templates/components/spinkit-chasing-dots.hbs"
    }
  });

  _exports.default = _default;
});
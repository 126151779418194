define("ev/controllers/team/show/manager", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    teamController: Ember.inject.controller('team/show'),
    changedPositions: {},
    attendanceModal: null,
    positionSaveStatus: '',
    matches: Ember.computed('model.matches', function () {
      return this.get('model.matches').sortBy('startAsc');
    }),
    attendable_events: Ember.computed('model.{matches,trainings}', function () {
      var events = Ember.A();
      this.get('model.matches').forEach(function (e) {
        events.pushObject(e);
      });
      this.get('model.trainings').forEach(function (e) {
        events.pushObject(e);
      });
      return events.sortBy('startAsc');
    }),
    official_members: Ember.computed('model.teamseason.assignments', function () {
      var members = Ember.A();
      this.get('model.teamseason.assignments').forEach(function (a) {
        if (a.get('training')) {
          a.set('exempt', true);
        } else if (a.get('involvement') < 1) {
          a.set('stars', Math.ceil(a.get('involvement') / 0.25));
        } else {
          a.set('exempt', true);
        }

        members.pushObject(a);
      });
      return members.sort(function (a, b) {
        if (a.get('captain') === b.get('captain')) {
          if (a.get('training') === b.get('training')) {
            return a.get('member.name').localeCompare(b.get('member.name'));
          }

          return a.get('training') ? 1 : -1;
        }

        return a.get('captain') ? -1 : 1;
      });
    }),
    members: Ember.computed('model.teamseason.assignments', function () {
      var members = Ember.A();
      this.get('model.teamseason.assignments').forEach(function (a) {
        if (!a.get('member.is_retired')) {
          members.pushObject(a);
        }
      });
      return members.sort(function (a, b) {
        if (a.get('captain') === b.get('captain')) {
          if (a.get('training') === b.get('training')) {
            return a.get('member.name').localeCompare(b.get('member.name'));
          }

          return a.get('training') ? 1 : -1;
        }

        return a.get('captain') ? -1 : 1;
      });
    }),
    positions: {},
    _loadPositions: Ember.observer('model', function () {
      var positions = {};
      this.members.forEach(function (member) {
        if (!member.get('training')) {
          positions[member.get('member.id')] = {
            setter: member.get('position_setter'),
            outside: member.get('position_outside'),
            middle: member.get('position_middle'),
            diagonal: member.get('position_diagonal'),
            libero: member.get('position_libero')
          };
        }
      });
      this.set('positions', positions);
    }),
    tasks: Ember.computed('model.officials', 'model.officials.*.referee_member', 'model.officials.*.counter_member', 'model.officials.*.counter_member2', function () {
      var events = Ember.A();
      var meta = this.get('model.officials.content.meta.match');
      var teamId = this.get('model._id');
      this.get('model.officials').forEach(function (official) {
        if (typeof meta[official.get('id')] !== 'undefined') {
          var officialMeta = meta[official.get('id')];

          if (String(officialMeta.referee_id) === teamId) {
            events.pushObject({
              type: 'referee',
              model: official,
              assigned: !!official.get('referee_member.id')
            });
          }

          if (String(officialMeta.counter_id) === teamId) {
            events.pushObject({
              type: 'counter',
              model: official,
              assigned: !!official.get('counter_member.id')
            });

            if (official.get('counter_double')) {
              events.pushObject({
                type: 'counter2',
                model: official,
                assigned: !!official.get('counter_member2.id')
              });
            }
          }
        }
      });
      return events.sortBy('model.startAsc');
    }),
    unassignedTasks: Ember.computed('tasks', 'tasks.*.assigned', function () {
      return this.tasks.filter(function (task) {
        return !Ember.get(task, 'assigned');
      });
    }),
    ajax: Ember.inject.service('ajax-ns'),
    actions: {
      savePositions: function savePositions() {
        var _this = this;

        this.set('positionSaveStatus', 'save.processing');
        var positions = this.positions;
        this.ajax.post('team/manager/positions', {
          contentType: 'application/json; charset=utf-8',
          data: {
            team: this.get('model._id'),
            positions: Object.keys(positions).reduce(function (c, m) {
              var ps = Ember.get(positions, m);
              c[m] = ['setter', 'outside', 'middle', 'diagonal', 'libero'].map(function (v) {
                return Ember.get(ps, v) ? v : null;
              }).filter(function (v) {
                return v !== null;
              });
              return c;
            }, {})
          }
        }).then(function (response) {
          if (response.status === 200) {
            _this.set('positionSaveStatus', 'save.success');
          } else {
            _this.set('positionSaveStatus', 'save.error');
          }
        }).catch(function () {
          _this.set('positionSaveStatus', 'save.error');
        });
      },
      memberPositionValueChanged: function memberPositionValueChanged(target) {
        var elm = (0, _jquery.default)(target);
        var memberId = elm.data('member');
        var position = elm.data('position');
        var checked = elm.is(':checked');
        this.set('positions.' + memberId + '.' + position, checked);
      }
    }
  });

  _exports.default = _default;
});
define("ev/templates/not-found", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "qsWGsv3O",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"ev-error-wrapper h-100 container\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"ev-error row h-100\"],[12],[2,\"\\n        \"],[10,\"div\"],[14,0,\"ev-error-img col-md-4  align-self-center\"],[12],[2,\"\\n            \"],[10,\"img\"],[14,\"src\",\"/images/error.png\"],[12],[13],[2,\"\\n        \"],[13],[2,\"\\n\\n        \"],[10,\"div\"],[14,0,\"ev-error-message col-md-8 align-self-center\"],[12],[2,\"\\n            \"],[10,\"h3\"],[12],[2,\"404 Not Found\"],[13],[2,\"\\n            \"],[10,\"p\"],[14,0,\"whoops\"],[12],[2,\"Whoops, the page you were looking for could not be found.\"],[13],[2,\"\\n            \"],[10,\"p\"],[12],[8,\"link-to\",[],[[\"@route\"],[\"index\"]],[[\"default\"],[{\"statements\":[[2,\"Return to Dashboard\"]],\"parameters\":[]}]]],[13],[2,\"\\n        \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "ev/templates/not-found.hbs"
    }
  });

  _exports.default = _default;
});
define("ev/overrides/textfield", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.TextField.reopen({
    init: function init() {
      var bindings = this.attributeBindings.map(function (v) {
        return v;
      }); // bind attributes beginning with 'data-'

      Object.keys(this).forEach(function (key) {
        if (key.substr(0, 5) === "data-" && bindings.indexOf(key) === -1) {
          bindings.pushObject(key);
        }
      });
      this.set("attributeBindings", bindings);

      this._super();
    }
  });

  _exports.default = _default;
});
define("ev/components/sponsorkliks-card", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="card card-sponsorkliks">
  	<div class="card-body">
  		<h4 class="card-title"><FaIcon @icon="box-heart" /> {{ t "sponsor.short" }}</h4>
  		<p>{{ t "sponsor.description" }}</p>
  		<div class="d-flex justify-content-between card-actions">
  			<div class="card-action">
                  <LinkTo @route="sponsor" class="text-white">{{ t "sponsor.moreinfo" }}</LinkTo>
  			</div>
  		</div>
  	</div>
  </div>
  */
  {
    "id": "5zxYcOef",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"card card-sponsorkliks\"],[12],[2,\"\\n\\t\"],[10,\"div\"],[14,0,\"card-body\"],[12],[2,\"\\n\\t\\t\"],[10,\"h4\"],[14,0,\"card-title\"],[12],[8,\"fa-icon\",[],[[\"@icon\"],[\"box-heart\"]],null],[2,\" \"],[1,[30,[36,0],[\"sponsor.short\"],null]],[13],[2,\"\\n\\t\\t\"],[10,\"p\"],[12],[1,[30,[36,0],[\"sponsor.description\"],null]],[13],[2,\"\\n\\t\\t\"],[10,\"div\"],[14,0,\"d-flex justify-content-between card-actions\"],[12],[2,\"\\n\\t\\t\\t\"],[10,\"div\"],[14,0,\"card-action\"],[12],[2,\"\\n                \"],[8,\"link-to\",[[24,0,\"text-white\"]],[[\"@route\"],[\"sponsor\"]],[[\"default\"],[{\"statements\":[[1,[30,[36,0],[\"sponsor.moreinfo\"],null]]],\"parameters\":[]}]]],[2,\"\\n\\t\\t\\t\"],[13],[2,\"\\n\\t\\t\"],[13],[2,\"\\n\\t\"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"t\"]}",
    "meta": {
      "moduleName": "ev/components/sponsorkliks-card.hbs"
    }
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});
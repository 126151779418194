define("ev/components/team/member/player", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <Profile::Member @member={{@member.member}}>
      <h5 class="media-heading">
          <span class="{{if @member.member.is_retired "retired em"}}">{{ @member.member.name }}</span>
          {{#if @member.captain}}
              {{#bs-tooltip title=(t "team.captain")}}
                  <span class="badge badge-secondary team-member-captain">C</span>
              {{/bs-tooltip}}
          {{/if}}
      </h5>
      {{#if @member.training}}
          <p><em class="text-muted">{{t "profile.trainingslid"}}</em></p>
      {{else}}
          <p>{{#if @member.shirt }}{{ @member.shirt }} - {{/if}}{{ ev-implode @member.position }}</p>
      {{/if}}
  </Profile::Member>
  */
  {
    "id": "iGag6ieQ",
    "block": "{\"symbols\":[\"@member\"],\"statements\":[[8,\"profile/member\",[],[[\"@member\"],[[32,1,[\"member\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[10,\"h5\"],[14,0,\"media-heading\"],[12],[2,\"\\n        \"],[10,\"span\"],[15,0,[31,[[30,[36,0],[[32,1,[\"member\",\"is_retired\"]],\"retired em\"],null]]]],[12],[1,[32,1,[\"member\",\"name\"]]],[13],[2,\"\\n\"],[6,[37,0],[[32,1,[\"captain\"]]],null,[[\"default\"],[{\"statements\":[[6,[37,3],null,[[\"title\"],[[30,[36,2],[\"team.captain\"],null]]],[[\"default\"],[{\"statements\":[[2,\"                \"],[10,\"span\"],[14,0,\"badge badge-secondary team-member-captain\"],[12],[2,\"C\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[2,\"    \"],[13],[2,\"\\n\"],[6,[37,0],[[32,1,[\"training\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"        \"],[10,\"p\"],[12],[10,\"em\"],[14,0,\"text-muted\"],[12],[1,[30,[36,2],[\"profile.trainingslid\"],null]],[13],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"        \"],[10,\"p\"],[12],[6,[37,0],[[32,1,[\"shirt\"]]],null,[[\"default\"],[{\"statements\":[[1,[32,1,[\"shirt\"]]],[2,\" - \"]],\"parameters\":[]}]]],[1,[30,[36,1],[[32,1,[\"position\"]]],null]],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"if\",\"ev-implode\",\"t\",\"bs-tooltip\"]}",
    "meta": {
      "moduleName": "ev/components/team/member/player.hbs"
    }
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});
define("ev/components/team/member/staff", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <Profile::Member @member={{@member.member}}>
      <h5 class="media-heading">
          <span class="{{if @member.member.is_retired "retired em"}}">{{ @member.member.name }}</span>
      </h5>
      {{#if @member.meta.title }}
          <p>{{ @member.meta.title }}</p>
      {{/if}}
  </Profile::Member>
  */
  {
    "id": "JstDyJA2",
    "block": "{\"symbols\":[\"@member\"],\"statements\":[[8,\"profile/member\",[],[[\"@member\"],[[32,1,[\"member\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[10,\"h5\"],[14,0,\"media-heading\"],[12],[2,\"\\n        \"],[10,\"span\"],[15,0,[31,[[30,[36,0],[[32,1,[\"member\",\"is_retired\"]],\"retired em\"],null]]]],[12],[1,[32,1,[\"member\",\"name\"]]],[13],[2,\"\\n    \"],[13],[2,\"\\n\"],[6,[37,0],[[32,1,[\"meta\",\"title\"]]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[10,\"p\"],[12],[1,[32,1,[\"meta\",\"title\"]]],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"if\"]}",
    "meta": {
      "moduleName": "ev/components/team/member/staff.hbs"
    }
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});
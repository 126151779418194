define("ev/templates/errors/not-found", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "epNwypCJ",
    "block": "{\"symbols\":[],\"statements\":[[10,\"h3\"],[12],[2,\"404 Not Found\"],[13],[2,\"\\n\"],[10,\"p\"],[14,0,\"whoops\"],[12],[2,\"Whoops, the page you were looking for could not be found.\"],[13],[2,\"\\n\"],[10,\"p\"],[12],[2,\"\\n    \"],[8,\"link-to\",[],[[\"@route\"],[\"index\"]],[[\"default\"],[{\"statements\":[[2,\"Return to Dashboard\"]],\"parameters\":[]}]]],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "ev/templates/errors/not-found.hbs"
    }
  });

  _exports.default = _default;
});
define("ev/templates/application", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "EgTvj3Jb",
    "block": "{\"symbols\":[],\"statements\":[[1,[30,[36,0],[[32,0,[\"title\"]]],null]],[2,\"\\n\"],[8,\"head-layout\",[],[[],[]],null],[2,\"\\n\"],[1,[30,[36,2],[[30,[36,1],null,null]],null]]],\"hasEval\":false,\"upvars\":[\"page-title\",\"-outlet\",\"component\"]}",
    "meta": {
      "moduleName": "ev/templates/application.hbs"
    }
  });

  _exports.default = _default;
});
define("ev/templates/landing/register/right", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "xJMrLeoG",
    "block": "{\"symbols\":[\"help\",\"feature\"],\"statements\":[[10,\"div\"],[14,0,\"right-wrapper\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,5,\"max-width: 600px; margin: 0 auto;\"],[12],[2,\"\\n        \"],[10,\"h4\"],[14,0,\"mb-4\"],[12],[1,[30,[36,0],[\"auth.signup.title\"],null]],[13],[2,\"\\n        \"],[10,\"ul\"],[14,0,\"list-group mb-4\"],[12],[2,\"\\n\"],[6,[37,3],[[30,[36,2],[[30,[36,2],[[30,[36,1],[\"auth.features\"],null]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"                \"],[10,\"li\"],[14,0,\"list-group-item\"],[12],[2,\"\\n                    \"],[8,\"fa-icon\",[[24,0,\"mr-2\"]],[[\"@icon\",\"@fixedWidth\"],[\"check\",true]],null],[2,\" \"],[1,[32,2]],[2,\"\\n                \"],[13],[2,\"\\n\"]],\"parameters\":[2]}]]],[2,\"        \"],[13],[2,\"\\n\\n        \"],[10,\"ul\"],[14,0,\"list-group\"],[12],[2,\"\\n\"],[6,[37,3],[[30,[36,2],[[30,[36,2],[[30,[36,1],[\"auth.signup.helps\"],null]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"                \"],[10,\"li\"],[14,0,\"list-group-item\"],[12],[2,\"\\n                    \"],[8,\"fa-icon\",[[24,0,\"blue-text text-darken-2 mr-2\"]],[[\"@icon\",\"@prefix\",\"@fixedWidth\"],[\"info-circle\",\"fal\",true]],null],[2,\" \"],[1,[32,1]],[2,\"\\n                \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"        \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"t\",\"t-array\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "ev/templates/landing/register/right.hbs"
    }
  });

  _exports.default = _default;
});
define("ev/overrides/route", [], function () {
  "use strict";

  Ember.Route.reopen({
    activate: function activate() {
      var cssClass = this.toCssClass();

      if (cssClass !== "route-application") {
        document.querySelector("body").classList.add(cssClass);
      }
    },
    deactivate: function deactivate() {
      document.querySelector("body").classList.remove(this.toCssClass());
    },
    toCssClass: function toCssClass() {
      return "route-" + this.routeName.replace(/\./g, "-").dasherize();
    }
  });
});
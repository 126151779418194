define("ev/templates/landing/verify/right", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "FlP1VwG+",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"right-wrapper\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,5,\"max-width: 600px; margin: 0 auto;\"],[12],[2,\"\\n\"],[6,[37,1],[[32,0,[\"model\",\"error\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"            \"],[10,\"div\"],[14,0,\"d-none d-lg-block\"],[12],[2,\"\\n                \"],[10,\"h4\"],[12],[1,[30,[36,0],[\"auth.verify.title\"],[[\"name\"],[[32,0,[\"model\",\"name\"]]]]]],[13],[2,\"\\n                \"],[10,\"p\"],[14,0,\"mb-0\"],[12],[1,[30,[36,0],[\"auth.verify.message\"],null]],[13],[2,\"\\n            \"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"            \"],[10,\"h4\"],[12],[1,[30,[36,0],[\"auth.verify.error_title\"],null]],[13],[2,\"\\n            \"],[10,\"p\"],[14,0,\"mb-0\"],[12],[1,[30,[36,0],[\"auth.verify.error_detail\"],null]],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"    \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"t\",\"unless\"]}",
    "meta": {
      "moduleName": "ev/templates/landing/verify/right.hbs"
    }
  });

  _exports.default = _default;
});
define("ev/templates/settings/cancel", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "8p+zv+My",
    "block": "{\"symbols\":[],\"statements\":[[10,\"p\"],[12],[1,[30,[36,0],[\"settings.cancel.text\"],[[\"htmlSafe\"],[true]]]],[13]],\"hasEval\":false,\"upvars\":[\"t\"]}",
    "meta": {
      "moduleName": "ev/templates/settings/cancel.hbs"
    }
  });

  _exports.default = _default;
});
define("ev/components/avatar-cropper", ["exports", "ember-cli-image-cropper/components/image-cropper"], function (_exports, _imageCropper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="img-container">
  	<img src={{image}}>
  </div>
  <div class="row align-items-center profile-picture-footer">
  	<div class="col-4 text-center">
  		<button {{action "cancel"}} class="btn btn-danger btn-large btn-floating"><FaIcon @icon="arrow-left" /></button>
  	</div>
  	<div class="col-4 text-center">
  		<div class="img-preview"></div>
  	</div>
  	<div class="col-4 text-center">
  		<button {{action "getCroppedAvatar"}} class="submit btn green btn-large btn-floating"><FaIcon @icon="check" /></button>
  	</div>
  </div>
  */
  {
    "id": "NXMp/qtl",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"img-container\"],[12],[2,\"\\n\\t\"],[10,\"img\"],[15,\"src\",[34,0]],[12],[13],[2,\"\\n\"],[13],[2,\"\\n\"],[10,\"div\"],[14,0,\"row align-items-center profile-picture-footer\"],[12],[2,\"\\n\\t\"],[10,\"div\"],[14,0,\"col-4 text-center\"],[12],[2,\"\\n\\t\\t\"],[11,\"button\"],[24,0,\"btn btn-danger btn-large btn-floating\"],[4,[38,1],[[32,0],\"cancel\"],null],[12],[8,\"fa-icon\",[],[[\"@icon\"],[\"arrow-left\"]],null],[13],[2,\"\\n\\t\"],[13],[2,\"\\n\\t\"],[10,\"div\"],[14,0,\"col-4 text-center\"],[12],[2,\"\\n\\t\\t\"],[10,\"div\"],[14,0,\"img-preview\"],[12],[13],[2,\"\\n\\t\"],[13],[2,\"\\n\\t\"],[10,\"div\"],[14,0,\"col-4 text-center\"],[12],[2,\"\\n\\t\\t\"],[11,\"button\"],[24,0,\"submit btn green btn-large btn-floating\"],[4,[38,1],[[32,0],\"getCroppedAvatar\"],null],[12],[8,\"fa-icon\",[],[[\"@icon\"],[\"check\"]],null],[13],[2,\"\\n\\t\"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"image\",\"action\"]}",
    "meta": {
      "moduleName": "ev/components/avatar-cropper.hbs"
    }
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, _imageCropper.default.extend({
    //override default options of cropper
    aspectRatio: 1,
    autoCropArea: 1,
    minCropBoxWidth: 100,
    minCropBoxHeight: 100,
    cropperContainer: ".img-container > img",
    previewClass: ".img-preview",
    croppedAvatar: null,
    responsive: true,
    viewMode: 2,
    dragMode: "move",
    rotatable: false,
    // checkOrientation : true,
    actions: {
      getCroppedAvatar: function getCroppedAvatar() {
        var container = this.$(this.cropperContainer);
        var action = this.action;
        action(container);
      },
      cancel: function cancel() {
        var action = this.cancel;
        action();
      }
    }
  }));

  _exports.default = _default;
});
define("ev/templates/team/index/_topnav", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "d+iWOFMd",
    "block": "{\"symbols\":[],\"statements\":[[10,\"li\"],[14,0,\"nav-item\"],[12],[2,\"\\n    \"],[8,\"link-to\",[[24,0,\"nav-link\"]],[[\"@route\",\"@replace\"],[\"team.index.index\",true]],[[\"default\"],[{\"statements\":[[2,\"Teams\"]],\"parameters\":[]}]]],[2,\"\\n\"],[13],[2,\"\\n\"],[10,\"li\"],[14,0,\"nav-item\"],[12],[2,\"\\n    \"],[8,\"link-to\",[[24,0,\"nav-link\"]],[[\"@route\",\"@replace\"],[\"team.index.compare\",true]],[[\"default\"],[{\"statements\":[[1,[30,[36,0],[\"team.schedule\"],null]]],\"parameters\":[]}]]],[2,\"\\n\"],[13],[2,\"\\n\"],[10,\"li\"],[14,0,\"nav-item\"],[12],[2,\"\\n    \"],[8,\"link-to\",[[24,0,\"nav-link\"]],[[\"@route\",\"@replace\"],[\"team.index.standings\",true]],[[\"default\"],[{\"statements\":[[1,[30,[36,0],[\"standings.title\"],null]]],\"parameters\":[]}]]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\"]}",
    "meta": {
      "moduleName": "ev/templates/team/index/_topnav.hbs"
    }
  });

  _exports.default = _default;
});
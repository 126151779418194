define("ev/helpers/ev-inarray", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }

  var _default = Ember.Helper.helper(function (value) {
    var response = false;

    if (value[0] === null || _typeof(value[0]) !== "object") {
      return null;
    }

    value[0].forEach(function (v) {
      if (v.get("guid") === value[1].get("guid")) {
        response = true;
        return;
      }
    });
    return response;
  });

  _exports.default = _default;
});
define("ev/components/committee/index-card", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <LinkTo @route="committee.show" @model={{@committee.committee.slug}} class="photo-card h160">
      <div class="card">
          <div class="mdl-card__title photo-block {{if @committee.photo 'hasPhoto'}}">
              <div class="photo-block-text">{{ @committee.committee.abbr }}</div>
              {{#if @committee.photo}}
                  <ResponsivePhoto @photo={{@committee.photo}} @top={{@committee.photo_top}} @bottom={{@committee.photo_bottom}} @maxHeight={{160}} @style="teamphoto_index" class="responsive-image photo-block-image" />
              {{/if}}
          </div>
          <div class="card-body">
              <span class="text-black">{{ @committee.committee.name }}</span>
          </div>
      </div>
  </LinkTo>
  */
  {
    "id": "piJi3Cbr",
    "block": "{\"symbols\":[\"@committee\"],\"statements\":[[8,\"link-to\",[[24,0,\"photo-card h160\"]],[[\"@route\",\"@model\"],[\"committee.show\",[32,1,[\"committee\",\"slug\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[10,\"div\"],[14,0,\"card\"],[12],[2,\"\\n        \"],[10,\"div\"],[15,0,[31,[\"mdl-card__title photo-block \",[30,[36,0],[[32,1,[\"photo\"]],\"hasPhoto\"],null]]]],[12],[2,\"\\n            \"],[10,\"div\"],[14,0,\"photo-block-text\"],[12],[1,[32,1,[\"committee\",\"abbr\"]]],[13],[2,\"\\n\"],[6,[37,0],[[32,1,[\"photo\"]]],null,[[\"default\"],[{\"statements\":[[2,\"                \"],[8,\"responsive-photo\",[[24,0,\"responsive-image photo-block-image\"]],[[\"@photo\",\"@top\",\"@bottom\",\"@maxHeight\",\"@style\"],[[32,1,[\"photo\"]],[32,1,[\"photo_top\"]],[32,1,[\"photo_bottom\"]],160,\"teamphoto_index\"]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"        \"],[13],[2,\"\\n        \"],[10,\"div\"],[14,0,\"card-body\"],[12],[2,\"\\n            \"],[10,\"span\"],[14,0,\"text-black\"],[12],[1,[32,1,[\"committee\",\"name\"]]],[13],[2,\"\\n        \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"if\"]}",
    "meta": {
      "moduleName": "ev/components/committee/index-card.hbs"
    }
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});
define("ev/templates/landing", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "VFk5q1iy",
    "block": "{\"symbols\":[\"loc\"],\"statements\":[[10,\"div\"],[14,0,\"container-fluid h-100\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n        \"],[10,\"div\"],[14,0,\"col-lg-5 erasmus-green col-left\"],[12],[2,\"\\n            \"],[10,\"div\"],[14,0,\"d-flex justify-content-between align-items-center flex-column py-5 h-100\"],[12],[2,\"\\n                \"],[10,\"div\"],[12],[2,\"\\n                    \"],[10,\"img\"],[14,\"src\",\"/images/logo-wide.png\"],[14,0,\"img-fluid logo\"],[12],[13],[2,\"\\n                \"],[13],[2,\"\\n                \"],[10,\"div\"],[14,0,\"form-section mb-4\"],[12],[2,\"\\n                    \"],[1,[30,[36,4],[[30,[36,3],[\"left\"],null]],null]],[2,\"\\n                \"],[13],[2,\"\\n                \"],[10,\"div\"],[12],[2,\"\\n\"],[6,[37,6],[[30,[36,5],[[30,[36,5],[[32,0,[\"locales\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[6,[37,2],[[32,1,[\"current\"]]],null,[[\"default\"],[{\"statements\":[[2,\"                            \"],[11,\"a\"],[24,6,\"#\"],[24,0,\"text-white mx-2 p-4\"],[4,[38,1],[\"click\",[30,[36,0],[[32,0,[\"switchLocale\"]],[32,1,[\"code\"]]],null]],null],[12],[1,[32,1,[\"name\"]]],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[1]}]]],[2,\"                \"],[13],[2,\"\\n            \"],[13],[2,\"\\n            \"],[10,\"img\"],[14,\"src\",\"/images/icon_white.png\"],[14,0,\"icon\"],[12],[13],[2,\"\\n        \"],[13],[2,\"\\n        \"],[10,\"div\"],[14,0,\"col-lg-7 d-flex justify-content-center align-items-center\"],[14,5,\"overflow: scroll;\"],[12],[2,\"\\n            \"],[1,[30,[36,4],[[30,[36,3],[\"right\"],null]],null]],[2,\"\\n        \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"fn\",\"on\",\"unless\",\"-outlet\",\"component\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "ev/templates/landing.hbs"
    }
  });

  _exports.default = _default;
});
define("ev/templates/settings/origin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "R9RtQ8ZY",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"form-group mt-0\"],[12],[2,\"\\n\\t\"],[8,\"place-autocomplete-field\",[],[[\"@value\",\"@handlerController\",\"@inputClass\",\"@placeholder\",\"@placeChangedCallback\",\"@types\",\"@setValueWithProperty\"],[[32,0,[\"origin_name\"]],[32,0],\"form-control place-autocomplete--input\",[30,[36,0],[\"settings.origin.placeholder\"],null],[30,[36,1],[[32,0],\"placeChanged\"],null],\"(cities)\",\"name\"]],null],[2,\"\\n\\t\"],[10,\"p\"],[14,0,\"text-muted\"],[12],[2,\"\\n\\t\\t\"],[10,\"small\"],[12],[1,[30,[36,0],[\"settings.origin.info\"],null]],[13],[2,\"\\n\\t\"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"t\",\"action\"]}",
    "meta": {
      "moduleName": "ev/templates/settings/origin.hbs"
    }
  });

  _exports.default = _default;
});
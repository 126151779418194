define("ev/templates/navbarred", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Jo4PLKd7",
    "block": "{\"symbols\":[],\"statements\":[[10,\"header\"],[12],[2,\"\\n    \"],[8,\"navigation/sidenav-bar\",[],[[],[]],null],[2,\"\\n    \"],[8,\"navigation/topnav-bar\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[1,[30,[36,1],[[30,[36,0],[\"secondary-nav\"],null]],null]],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n\"],[13],[2,\"\\n\"],[10,\"main\"],[14,1,\"route\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"secondary-nav-mobile hidden-md-up\"],[12],[2,\"\\n        \"],[10,\"ul\"],[14,0,\"nav nav-tabs evb-tabs\"],[12],[2,\"\\n            \"],[1,[30,[36,1],[[30,[36,0],[\"secondary-nav\"],null]],null]],[2,\"\\n        \"],[13],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"container\"],[12],[2,\"\\n        \"],[1,[30,[36,1],[[30,[36,0],[\"fixed\"],null]],null]],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"container-fluid\"],[12],[2,\"\\n        \"],[1,[30,[36,1],[[30,[36,0],null,null]],null]],[2,\"\\n    \"],[13],[2,\"\\n    \"],[1,[30,[36,1],[[30,[36,0],[\"full\"],null]],null]],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"-outlet\",\"component\"]}",
    "meta": {
      "moduleName": "ev/templates/navbarred.hbs"
    }
  });

  _exports.default = _default;
});